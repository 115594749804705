import {
  COLORS,
  LBTAccordion,
  LBTLabel,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { StaticArticles } from '../../components/StaticArticles';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { SolaryFits } from '../../components/SolaryFits';
import BoldSpan from '../../components/answerBoxes/BoldSpan';
import {
  AnswerBoxOne,
  AnswerBoxTwo,
} from '../../components/answerBoxes/FifthSalaryTerminationFourthTwoBoxes';
import { ARTICLES, Image } from './Fifth';
import useTrackPage from '../../hooks/useTrackPage';
import { navMenuState } from '@laborability/commons';

const ACCORDION_ITEMS = [
  {
    question: 'Come si ottiene il rimborso?',
    answer: <AnswerBoxOne />,
  },
  {
    question: 'A chi si può chiedere tutela?',
    answer: <AnswerBoxTwo />,
  },
];

const Fifth04 = () => {
  const setNavColor = useSetRecoilState(navMenuState);

  useEffect(() => {
    setNavColor(COLORS.getInstance().WHITE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useTrackPage(
    'Cessione del quinto - Articolo 4',
    '/app/cessione-quinto/articol-4',
  );

  return (
    <>
      <Section backgroundColor={COLORS.getInstance().WHITE}>
        <LBTSpacer spacing={2} />
        <LBTLabel
          variant="delaDisplay"
          component="h1"
          color={COLORS.getInstance().MINT_TONIC_MEDIUM}
        >
          Come chiedere il rimborso della cessione del quinto?
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle" component="h2">
          Cos'è il rimborso della cessione del quinto, come si può ottenere e a
          chi chiedere tutela
        </LBTLabel>
        {ARTICLES[3].image && <Image src={ARTICLES[3].image} />}

        <LBTSpacer spacing={4} />
        <LBTLabel
          variant="bodyText"
          textAlign="left"
          paddingBottom={'16px'}
          paddingTop={'48px'}
        >
          La cessione del quinto dello stipendio è un modo con cui un soggetto
          può ripagare un prestito contratto con un istituto finanziario cedendo
          a questo una parte della propria retribuzione mensile. Le rate
          coincidono, quindi, con la quota di stipendio ceduta. L'istituto
          finanziario non si limita a pretendere la restituzione delle somme
          prestate, ma chiede anche il pagamento di spese (spese di incasso),
          commissioni (per il perfezionamento del finanziamento, per la gestione
          del finanziamento, costi assicurativi) e interessi. Il{' '}
          <LBTSpacer spacing={2} isFixed />
          <BoldSpan>
            finanziamento può essere estinto prima del termine
          </BoldSpan>{' '}
          previsto, nel caso in cui il debito residuo venga interamente pagato o
          nel caso in cui il finanziamento venga rinegoziato (rinnovo della
          cessione con estinzione della precedente).
          <LBTSpacer spacing={2} isFixed /> In questi casi il debitore ha
          diritto alla{' '}
          <BoldSpan>riduzione del costo totale del credito</BoldSpan>, il quale
          include tutti i costi posti a suo carico al momento della concessione
          del finanziamento.
          <LBTSpacer spacing={2} isFixed />
          Il <BoldSpan>diritto al rimborso</BoldSpan> è stabilito dal Testo
          Unico Bancario e dalla Direttiva Europea sul credito al consumo, e le
          clausole che escludono tale diritto sono nulle. dovutagli dall'INPS.
        </LBTLabel>
        {ACCORDION_ITEMS.map((item, index) => (
          <LBTAccordion
            key={index}
            uniqueKey={`${index}`}
            details={item.answer}
            summary={item.question}
          />
        ))}
        <LBTSpacer spacing={4} />
      </Section>

      <SolaryFits></SolaryFits>

      <Section backgroundColor={COLORS.getInstance().WHITE}>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h3">
          Vai ai prossimi articoli
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <StaticArticles toExclude={[3]} />
      </Section>
    </>
  );
};

export default Fifth04;
