import md5 from 'md5';

import {
  currentUserState,
  currentCompanyConfigState,
} from '@laborability/commons';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useRecoilValue } from 'recoil';

const TEST_DOMAINS = [
  'laborability.com',
  'shritalia.com',
  'growthers.io',
  'growthers.it',
  'growthers.com',
  'adv-player.com',
];

function useTrackPage(pathTitle: string, pathLocation: string) {
  const currentUser = useRecoilValue(currentUserState);
  const currentCompany = useRecoilValue(currentCompanyConfigState);

  useEffect(() => {
    if (pathTitle === '' || pathLocation === '') return;

    const email = currentUser.email;
    const domain = email?.substring(email?.indexOf('@') + 1)!;

    console.log('@@@tagManager', {
      event: 'page_view',
      page_location: pathLocation,
      page_title: pathTitle,
      company: `company-${currentCompany.id ?? 'id'}`,
      user_test: TEST_DOMAINS.includes(domain) ? 'true' : 'false',
      id_user: currentUser.email ? md5(currentUser.email) : '',
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        page_location: pathLocation,
        page_title: pathTitle,
        company: `company-${currentCompany.id ?? 'id'}`,
        user_test: TEST_DOMAINS.includes(domain) ? 'true' : 'false',
        id_user: currentUser.email ? md5(currentUser.email) : '',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathLocation, pathTitle]);
}

export default useTrackPage;
