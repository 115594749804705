import {
  Misura,
  getAllMisureCallback,
  measuresState,
  searchMisuraCallback,
  useBreakpoint,
} from '@laborability/commons';
import { useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import InfiniteDiscover from '../../components/InfiniteDiscover';
import {
  CardVariant,
  COLORS,
  IconSearchComponent,
  IconsSize,
  IconsStyle,
  LBTCard,
  LBTLabel,
  LBTSpacer,
  LBTTextField,
  Section,
} from '@laborability/components';
import { Grid } from '@mui/material';
import { APP_ROUTES } from '../../Routes';
import useTrackPage from '../../hooks/useTrackPage';
import { useEffect, useRef, useState } from 'react';
import { navMenuState } from '@laborability/commons';

export default function SearchMisure() {
  const navigate = useNavigate();
  const fetchMisure = useRecoilCallback(searchMisuraCallback, []);
  const fetchAllMisure = useRecoilCallback(getAllMisureCallback, []);
  const setNavColor = useSetRecoilState(navMenuState);
  const misure = useRecoilValue(measuresState);
  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [totalItems, setTotalItems] = useState<number>(0);
  const { isDesktop } = useBreakpoint();

  const [showComponent, setShowComponent] = useState(false);

  const handleButtonClick = () => {
    setShowComponent(true);
  };

  const getMisure = async (
    skip: number,
    limit: number,
    progressive: boolean,
    params?: { query?: string },
  ) => {
    const res = await fetchMisure({
      query: params?.query ?? '',
      skip,
      limit,
      published: true,
      progressive,
    });
    if (!res || !res.data) return [];
    return res.data;
  };

  const getAllMisureCount = async () => {
    const res = await fetchAllMisure({ limit: 999 });
    if (!res || !res.data) return [];
    const notDraftItems = res.data.items.filter(
      (item: Misura) => item.draft === false,
    );
    setTotalItems(notDraftItems.length);
  };

  useTrackPage(`Ricerca libera`, `/app/ricerca-libera`);

  useEffect(() => {
    setNavColor(COLORS.getInstance().WHITE);

    getAllMisureCount();
  }, []);

  return (
    <Section backgroundColor={COLORS.getInstance().WHITE}>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h1">
        Cerca tra tutti i bonus
      </LBTLabel>
      <LBTSpacer spacing={6} />
      <LBTTextField
        ref={inputRef}
        datatestid="wide_search_input_field"
        sx={{ maxWidth: '504px' }}
        onKeyUp={event => {
          if (event.key === 'Enter') {
            setSearch(inputRef.current?.value ?? undefined);
            handleButtonClick();
          }
        }}
        placeholder="Cerca"
        onChange={() => {}}
        startIcon={
          <IconSearchComponent
            datatestid="wide_search_result_link"
            size={IconsSize.MEDIUM}
            style={IconsStyle.OUTLINE}
            color={COLORS.getInstance().BLACK}
          />
        }
      />
      <LBTSpacer spacing={2} />
      {showComponent ? (
        <InfiniteDiscover
          items={misure}
          getMoreItems={getMisure}
          params={{ query: search }}
          hasTotalItemsLabel
          customLabelDescription=" totali"
          renderItem={(item: Misura) => (
            <Grid item mobile={12} desktop={6} display="flex">
              <LBTCard
                onClick={() =>
                  navigate(`/${APP_ROUTES.MEASURE_DETAIL}/${item.id}`)
                }
                backgroundColor={COLORS.getInstance().STATUS_INFO_BLUE_MAIN}
                title={item.name}
                description={item.description}
                tag={item.categories
                  ?.find(category =>
                    item.categories!.length === 2
                      ? !!category.parent_id
                      : category.parent_id === null,
                  )
                  ?.name?.toLowerCase()}
                news={item.news ?? false}
                expired={item.expired ?? false}
                expiringSoon={item.expiring_soon ?? false}
                cardVariant={CardVariant.CONTAINED}
                upcoming={item.upcoming ?? false}
              />
            </Grid>
          )}
        />
      ) : (
        <LBTLabel
          variant="sourceCaption"
          component="small"
          maxWidth={isDesktop ? '1032px' : undefined}
        >
          {`${totalItems} risultati tra cui cercare`}
        </LBTLabel>
      )}
    </Section>
  );
}
